import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/Luis/Documents (syncthing)/Projects/Jacqueline Website/jacqueline-website/theme/src/components/common/Layout/index.jsx";
import { SEO } from "gatsby-theme-portfolio";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Services" mdxType="SEO" />
    <h1>{`Services page`}</h1>
    <p>{`Test it out`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      